<template>
  <ValidationProvider
    :rules="{
      required: true,
      validDate: true,
      past: field.validation === DATE_VALIDATION.PAST,
      future: field.validation === DATE_VALIDATION.FUTURE,
    }"
    v-slot="{ errors, validated, valid }"
  >
    <b-field
      :label="field.label"
      v-if="visible"
      :type="{ 'is-danger': errors[0], 'is-success': validated && valid }"
      :message="errors"
      class="validator-field"
    >
      <b-datepicker
        :id="`prelimInput_${field.identifier}`"
        v-model="selectedValue"
        locale="hu-HU"
        icon-pack="fa"
        icon="calendar"
        trap-focus
        :mobile-native="false"
        :years-range="[-100, 60]"
        :focused-date="
          selectedValue
            ? new Date(selectedValue)
            : prefillDate
            ? new Date(prefillDate)
            : new Date()
        "
        :placeholder="/*withoutDay ? prefillDate : ''*/ ''"
      >
      </b-datepicker>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { formatDate } from "@/utils/util";
import { DATE_VALIDATION } from "@/utils/const";

export default {
  name: "PreliminaryDatePickerQuestion",
  props: {
    field: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    mortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: null,
      prefillDate: null,
      withoutDay: false,
      DATE_VALIDATION,
    };
  },
  mounted() {
    const storedDate = this.$store.getters[
      this.mortgage
        ? "mortgagePreliminaryAssessment/answer"
        : "preliminaryAssessment/answer"
    ](this.field.identifier);

    console.log(storedDate);

    if (storedDate) {
      if (storedDate.toString().length === 7) {
        this.withoutDay = true;
        this.prefillDate = storedDate;
      } else {
        this.selectedValue = new Date(storedDate);
      }
    }
    this.$watch("selectedValue", this.valueWatcher);
  },
  methods: {
    valueWatcher() {
      if (this.selectedValue?.toString().length !== 7) {
        this.withoutDay = false;
        this.$store.dispatch(
          this.mortgage
            ? "mortgagePreliminaryAssessment/addData"
            : "preliminaryAssessment/addData",
          {
            question: this.field.identifier,
            answer: formatDate(this.selectedValue),
          }
        );
      }
    },
  },
};
</script>

<style scoped></style>
