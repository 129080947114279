var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":{
    required: true,
    validDate: true,
    past: _vm.field.validation === _vm.DATE_VALIDATION.PAST,
    future: _vm.field.validation === _vm.DATE_VALIDATION.FUTURE,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
  var validated = ref.validated;
  var valid = ref.valid;
return [(_vm.visible)?_c('b-field',{staticClass:"validator-field",attrs:{"label":_vm.field.label,"type":{ 'is-danger': errors[0], 'is-success': validated && valid },"message":errors}},[_c('b-datepicker',{attrs:{"id":("prelimInput_" + (_vm.field.identifier)),"locale":"hu-HU","icon-pack":"fa","icon":"calendar","trap-focus":"","mobile-native":false,"years-range":[-100, 60],"focused-date":_vm.selectedValue
          ? new Date(_vm.selectedValue)
          : _vm.prefillDate
          ? new Date(_vm.prefillDate)
          : new Date(),"placeholder":/*withoutDay ? prefillDate : ''*/ ''},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }